<template>
  <div class="container text-center exhibits">
    <h2 class="mb-12">
      Rahmenprogramm
    </h2>
    <v-tabs
      v-model="tab"
      background-color="transparent"
    >
      <v-tab
        v-for="(item, i) in tabs"
        :key="i"
      >
        <span class="d-none d-md-inline">{{ item.title }}</span>
        <span class="d-md-none" v-if="item.title === 'Donnerstag'">Do</span>
        <span class="d-md-none" v-if="item.title === 'Freitag'">Fr</span>
        <span class="d-md-none" v-if="item.title === 'Samstag'">Sa</span>
        <span class="d-md-none" v-if="item.title === 'Durchgehend'">Immer</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, i) in tabs"
        :key="i"
      >
        <Slider :slides="item.slides" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Slider from '@/components/program/Slider.vue'
export default {
  name: 'Exhibit',
  components: {
    Slider
  },
  data () {
    return {
      tab: new Date().getDay() - 4,
      tabs: [
        {
          title: 'Donnerstag',
          slides: [
            {
              title: 'Antragsteller*innen Treffen',
              text: '<p>Ein Treffen NUR für Antragsteller*innen, die noch offene, zu verhandelnde Anträge haben.</p>',
              image: {
                src: '/bdk23/notes.jpg',
                alt: 'Eine stilisierte Darstellung von Notizblock und Stif'
              },
              place: 'Donnerstag, 14:00 - 16:00 in "Wien"'
            },
            {
              title: 'GRÜNFUNK: Regierungsdialog mit Claudia Müller',
              text: '<p class="d-none d-lg-block">Auch in diesem Jahr bieten wir Workshops mit Vertreter*innen grüner Ministerien zu folgendem Thema an:</p>' +
                '<p>„Halbzeit in der Regierung: Was haben die Staatsminister*innen & Staatssekretär*innen in den letzten 2 Jahren geschafft und wie hat sich Ihre Arbeit seit Januar 2022 verändert?“</p>' +
                '<p class="font-weight-bold">Claudia Müller ist Staatsekretärin im Bundesministerium für Ernährung und Landwirtschaft.</p>',
              image: {
                src: '/bdk23/Regierungsdialog_CM.png',
                alt: 'Ein Foto einer jungen Frau mit mittellangen dunkelblonden Haaren und blauen Augen',
                class: 'd-none d-sm-block'
              },
              place: 'Donnerstag, 15:30 - 16:15 in "Straßburg"'
            },
            {
              title: 'GRÜNFUNK: Regierungsdialog mit Tobias Lindner',
              text: '<p class="d-none d-lg-block">Auch in diesem Jahr bieten wir Workshops mit Vertreter*innen grüner Ministerien zu folgendem Thema an:</p>' +
                '<p>„Halbzeit in der Regierung: Was haben die Staatsminister*innen & Staatssekretär*innen in den letzten 2 Jahren geschafft und wie hat sich Ihre Arbeit seit Januar 2022 verändert?“</p>' +
                '<p class="font-weight-bold">Dr. Tobias Lindner ist Staatsminister im Auswärtigen Amt.</p>',
              image: {
                src: '/bdk23/Regierungsdialog_TL.png',
                alt: 'Ein Foto von Tobias Lindner in Anzug und mit einer gestreiften blauen Krawatte',
                class: 'd-none d-sm-block'
              },
              place: 'Donnerstag, 15:30 - 16:15 in "Straßburg"'
            },
            {
              title: 'Delegiertentreffen Nordrhein-Westfalen',
              text: '<p>Ein Treffen für alle Delegierten und Ersatzdelegierten aus Nordrhein-Westfalen.</p>',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Donnerstag, 16:00 in "Brüssel"'
            },
            {
              title: 'Delegiertentreffen der Grünen Jugend',
              text: '<p>Ein Treffen für alle Delegierten und Ersatzdelegierten der Grünen Jugend.</p>',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Donnerstag, 17:30 in "Brüssel"'
            }
          ]
        },
        {
          title: 'Freitag',
          slides: [
            {
              title: 'Delegiertentreffen Baden-Württemberg',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus Baden-Württemberg.',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 8:30 in "Wien"'
            },
            {
              title: 'GRÜNFUNK: Regierungsdialog mit Franziska Brantner',
              text: '<p class="d-none d-lg-block">Auch in diesem Jahr bieten wir Workshops mit Vertreter*innen grüner Ministerien zu folgendem Thema an:</p>' +
                '<p>„Halbzeit in der Regierung: Was haben die Staatsminister*innen & Staatssekretär*innen in den letzten 2 Jahren geschafft und wie hat sich Ihre Arbeit seit Januar 2022 verändert?“</p>' +
                '<p class="font-weight-bold">Dr. Franziska Brantner ist Staatssekretärin m Bundesministerium für Wirtschaft und Klimaschutz.</p>',
              image: {
                src: '/bdk23/Regierungsdialog_FB.png',
                alt: 'Ein Foto einer Frau mit kurzen blonden Haaren',
                class: 'd-none d-sm-block'
              },
              place: 'Freitag, 8:30 in "Straßburg 1"'
            },
            {
              title: 'GRÜNFUNK: Regierungsdialog mit Anna Lührmann und Katja Keul',
              text: '<p class="d-none d-lg-block">Auch in diesem Jahr bieten wir Workshops mit Vertreter*innen grüner Ministerien zu folgendem Thema an:</p>' +
                '<p>„Halbzeit in der Regierung: Was haben die Staatsminister*innen & Staatssekretär*innen in den letzten 2 Jahren geschafft und wie hat sich Ihre Arbeit seit Januar 2022 verändert?“</p>' +
                '<p class="font-weight-bold">Dr. Anna Lührmann und Katja Keul sind Staatsminister*innen im Auswärtigen Amt.</p>',
              image: {
                src: '/bdk23/Regierungsdialog_AL.png',
                alt: 'Ein Foto von Anna Lührmann und ein Foto von Katja Keul',
                class: 'd-none d-md-block'
              },
              place: 'Freitag, 8:30 in "Straßburg 2"'
            },
            {
              title: 'Delegiertentreffen aller östlichen Bundesländer',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus den östlichen Bundesländern (Mecklenburg-Vorpommern, Sachsen, Brandenburg, Sachsen-Anhalt, Thüringen).',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 8:30 in "Rom"'
            },
            {
              title: 'BIPoC Vernetzungstreffen',
              text: '<p>Wir bieten ein Vernetzungstreffen für BIPoC im Rahmen der BDK an.</p>' +
                '<p>Dort wollen wir uns kennenlernen, vernetzen und gegenseitig empowern. Außerdem besteht die Möglichkeit, mit unserer stellvertretenden Bundesvorsitzenden und vielfaltspolitischen Sprecherin, Pegah Edalatian, Bundes- und Landtagsabgeordneten, sowie Mitgliedern der Landesvorstände in den Austausch zu treten.</p>' +
                '<p>Diese Veranstaltung richtet sich ausschließlich an Personen, die sich als BIPoC positionieren. BIPoC ist eine Abkürzung für Black, Indigenous und People of Color, also für Menschen, die von Rassismus oder Antisemitismus betroffen sind.</p>',
              image: {
                src: '/bdk23/vielfalt.png',
                alt: 'Logo der AG Vielfalt mit einer stilisierten Sonnenblume und 5 BIPoC Menschen, darunter eine Person mit Sehbehinderung und eine mit Regenbogen T-Shirt',
                class: 'd-none d-md-block'
              },
              place: 'Freitag, 9:00 - 9:30 in "Brüssel'
            },
            {
              title: 'Delegiertentreffen Rheinland-Pfalz',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus Rheinland-Pfalz.',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 9:30 in "Prag"'
            },
            {
              title: 'Delegiertentreffen Niedersachsen',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus Niedersachsen.',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 9:30 in "Wien"'
            },
            {
              title: 'Delegiertentreffen Sachsen-Anhalt',
              text: '<p>Ein Treffen für alle Delegierten und Ersatzdelegierten aus Sachsen-Anhalt.</p>',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 9:30 in "Rom'
            },
            {
              title: 'Delegiertentreffen Bayern',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus Bayern.',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 10:00 in "Brüssel"'
            },
            {
              title: 'Delegiertentreffen Saarland',
              text: 'Ein Treffen für alle Delegierten und Ersatzdelegierten aus dem Saarland.',
              image: {
                src: '/bdk23/discussion.jpg',
                alt: 'Eine stilisierte Darstellung einer diskutierenden Menschengruppe'
              },
              place: 'Freitag, 13:00 in "Brüssel"'
            },
            {
              title: 'Haustürwahlkampf für die Europawahl 2024 mit Dörte und Ben',
              text: '<p>Wir wissen, Haustürwahlkampf ist das erfolgreichste Wahlkampfinstrument!</p>' +
                '<p>Aber Du weißt noch nicht, wie Du das bei Dir umsetzen kannst? Dann komm vorbei. Wir freuen uns darauf mit Dir und Euch Ideen und Informationen auszutauschen.</p>',
              image: {
                src: 'bdk23/sunflower.png',
                alt: 'Eine stilisierte Sonnenblume auf grünem Hintergrund'
              },
              place: 'Freitag, 14:00 - 16:00 am Grünen Stand'
            },
            {
              title: 'Europaempfang',
              text: 'Mit diesem feierlichen Empfang starten wir gemeinsam offiziell in den Europa Wahlkampf. Es wird ein Konzert sowie Getränke geben.',
              image: {
                src: '/bdk23/europe.jpg',
                alt: 'Die Europaflagge auf grünem Hintergrund'
              },
              place: 'Freitag, 24:00 in der Aktionshalle'
            }
          ]
        },
        {
          title: 'Samstag',
          slides: [
            {
              title: 'GRÜNFUNK: Regierungsdialog mit Michael Kellner',
              text: '<p class="d-none d-lg-block">Auch in diesem Jahr bieten wir Workshops mit Vertreter*innen grüner Ministerien zu folgendem Thema an:</p>' +
                '<p>Was haben die Staatsminister*innen & Staatssekretär*innen in den letzten 2 Jahren geschafft und wie hat sich Ihre Arbeit seit Januar 2022 verändert?“</p>' +
                '<p>Michael Kellner ist Staatssekretär im Bundesministerium für Wirtschaft und Klimaschutz.</p>',
              image: {
                src: '/bdk23/Regierungsdialog_MK.png',
                alt: 'Ein Foto von Michael Kellner in Anzug und einer blauen Krawatte mit hellen Punkten',
                class: 'd-none d-sm-block'
              },
              place: 'Samstag, 8:30 in "Brüssel"'
            },
            {
              title: 'Vorstellung Vielfaltsumfrage und anschließender Workshop',
              text: '<p>In diesem Workshop gibt es die Möglichkeit gemeinsam mit Pegah Edalatian, der stellvertretenden Bundesvorsitzenden und vielfaltspolitischen Sprecherin von Bündnis 90/ Die Grünen, über die Ergebnisse der ersten Vielfaltsumfrage zu reden und sich über die daraus ableitenden Maßnahmen auszutauschen.</p>' +
                '<p>Das Vielfaltsstatut gibt uns unter anderem den Auftrag, regelmäßig wissenschaftlich fundiert zu evaluieren, wie vielfältig wir als Partei bereits aufgestellt sind und welche gesellschaftlichen diskriminierten Gruppen bei uns noch unterrepräsentiert sind. Die Ergebnisse und Maßnahmen möchten wir nun mit euch diskutieren.</p>',
              image: {
                src: '/bdk23/vielfalt.png',
                alt: 'Logo der AG Vielfalt mit einer stilisierten Sonnenblume und 5 BIPoC Menschen, darunter eine Person mit Sehbehinderung und eine mit Regenbogen T-Shirt',
                class: 'd-none d-md-block'
              },
              place: 'Samstag, 8:45 - 9:30 in "Straßburg"'
            },
            {
              title: 'Ostfrühstück',
              text: '<p>Im Namen des Bundesvorstandes lädt Heiko alle Delegierten der Ost-Bundesländer und deren Gäste zu einem gemeinsamen Frühstück ein.</p>' +
                '<p class="d-none d-lg-block">Wie bereits bei der BDK im vergangenen Jahr wird es bei Kaffee, belegten Brötchen und Gebäck die Möglichkeit geben, sich mit Mitgliedern aus anderen Ost-Kreisverbänden zu vernetzen, auszutauschen und Brücken für die gemeinsame Arbeit zu bauen.</p>' +
                '<p class="d-none d-lg-block">Um ausführlicher ins Gespräch kommen zu können, werden wir kleinere Gruppen zu den Themen Wirtschaft/Öko/Verkehr, Soziales/Gesundheit/Regionalentwicklung, Außen/Frieden/Handel und Demokratie/Vielfalt/Freiheit und deren Ostbezug anbieten.</p>',
              image: {
                src: '/bdk23/selfie.jpg',
                alt: 'Ein Foto einer Gruppe Delegierter mit MNS von einer früheren BDK'
              },
              place: 'Samstag, 10:00 in "Brüssel"'
            },
            {
              title: 'Ask Me Anything mit den stellvertretenden Vorsitzenden',
              text: '<p>Hier habt ihr die Gelegenheit, mit unseren frisch gwählten stellvertretenden Bundesvorsitzenden in einen persönlichen Austausch zu kommen. Denn hier stehen sie euch Rede und Antwort.</p>',
              image: {
                src: 'bdk23/sunflower.png',
                alt: 'Eine stilisierte Sonnenblume auf grünem Hintergrund'
              },
              place: 'Samstag, 11:40 - 12:00 am Grünen Stand'
            },
            {
              title: 'Party',
              text: '<p>Was wäre eine BDK ohne Party?!</p>' +
                '<p>Wir wollen es gar nicht wissen. Deshalb auch dieses Mal trotz eines vollen Programms: Wir freuen uns darauf, mit allen zusammen zu feiern.</p>',
              image: {
                src: '/bdk23/party.jpg',
                alt: 'Ein Emoji mit Partyhut'
              },
              place: 'Samstag, 23:00 in der Aktionshalle'
            }
          ]
        },
        {
          title: 'Durchgehend',
          slides: [
            {
              title: 'Veranstaltungen der LVs Brandenburg, Sachsen & Thüringen',
              text: '<p>In allen drei Bundesländern finden nächstes Jahr Landtagswahlen statt. Um zu informieren, zu mobilisieren und ins Gespräch zu kommen, bieten sie daher am Grünen Stand diverse Veranastaltungen mit Gästen wie Ska Keller, Michael Kellner sowie einzelnen Landesvorsitzenden und Fraktionsmitgliedern aus den Ländern an.</p>' +
                '<p>Am Grünen Stand befindet sich eine Übersicht zu den jeweiligen Veranstaltungen, inkl. der Uhrzeiten. Schaut vorbei!</p>',
              image: {
                src: 'bdk23/sunflower.png',
                alt: 'Eine stilisierte Sonnenblume auf grünem Hintergrund'
              }
            }
          ]
        }
      ]
    }
  }
}
</script>

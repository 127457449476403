<template>
  <div class="container infos pb-11">
    <h2 class="text-center mb-12">Hilfreiche Links</h2>
    <div v-if="links" class="text-center">
      <p
        v-for="(link, i) in links"
        :key= i>
        <a :href="link.href">{{ link.text }}</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Infos',
  data () {
    return {
      links: [
        {
          text: "Grüne.de",
          href: "https://www.gruene.de"
        },
        {
          text: "Wissenswerk",
          href: "https://wissenswerk.gruene.de/"
        },
        {
          text: "Antragsgrün",
          href: "https://antragsgruen.de/"
        },
        {
          text: "Lageplan",
          href: "https://gruene.de/bdk23-lageplan"
        },
        {
          text: "Fahrplan Busshuttle",
          href: "https://gruene.de/bdk23-shuttlebus"
        },
        {
          text: "Impressum",
          href: "https://gruene.de/impressum"
        }
      ]
    }
  }
}
</script>

function getBaseUrl () {
  return 'https://@abstimmung.netzbegruenung.de/api/meeting/'
}

function getSupportInfo () {
  return {
    name: 'verdigado',
    mail: 'support@verdigado.com',
    mailSubject: 'API-Fehler Präsidiumsansicht'
  }
}

function getDefaultConfig () {
  return {
    title: 'Grüne Veranstaltung',
    theme: 'gruene',
    language: 'DE',
    colors: {
      primary: '#008939',
      background: '#005437',
      headline: '#ffffff',
      text: '#ffffff',
      hashtag: '#0BA1DD',
      bar: '#FFF17A',
      button: '#008939',
      highlight: '#FFF17A',
      link: '#0BA1DD'
    },
    fonts: {
      headlineFontFamily: 'GrueneType',
      bodyFontFamily: 'PTSans',
      headlineTransform: 'none',
      bodyTransform: 'none',
      headlineFontWeight: 'normal',
      bodyFontWeight: 'normal',
      headlineFontStyle: 'italic',
      bodyFontStyle: 'none'
    },
    views: [
      {
        title: 'Programm',
        icon: 'mdi-eye',
        route: '/',
        active: true,
        type: 'program'
      }
    ]
  }
}

function getThemeConfig (theme) {
  switch (theme) {
    case 'bdk-20':
      return {
        colors: {
          primary: '#004743',
          background: '#004743',
          headline: '#00ff97',
          text: '#ffffff',
          hashtag: '#ff345f',
          bar: '#ffffff',
          button: '#004743',
          highlight: '#00ff97',
          link: '#00ff97'
        },
        fonts: {
          headlineFontFamily: 'Anton',
          bodyFontFamily: 'Arvo',
          headlineTransform: 'uppercase',
          bodyTransform: 'none',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'italic',
          bodyFontStyle: 'none'
        }
      }
    case 'bdk-21':
      return {
        colors: {
          primary: '#a0c864',
          background: '#a0c864',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#f06464',
          bar: '#ffe100',
          button: '#a0c864',
          highlight: '#ffe100',
          link: '#ffe100'
        },
        fonts: {
          headlineFontFamily: 'BereitBold',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'uppercase',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
    case 'bdk-22':
      return {
        colors: {
          primary: '#00694f',
          background: '#00694f',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#00a57e',
          bar: '#ffffff',
          button: '#00a57e',
          highlight: '#ffffaa',
          link: '#0BA1DD'
        },
        fonts: {
          headlineFontFamily: 'BereitBold',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'none',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
    case 'classic':
      return {
        colors: {
          primary: '#3c8025',
          background: '#3c8025',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#bf0069',
          bar: '#ffd500',
          button: '#3c8025',
          highlight: '#ffd500',
          link: '#ffd500'
        },
        fonts: {
          headlineFontFamily: 'Arvo',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'uppercase',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'none',
          bodyFontStyle: 'none'
        }
      }
    default:
      return {
        colors: {
          primary: '#008939',
          background: '#005437',
          headline: '#ffffff',
          text: '#ffffff',
          hashtag: '#0BA1DD',
          bar: '#FFF17A',
          button: '#008939',
          highlight: '#FFF17A',
          link: '#0BA1DD'
        },
        fonts: {
          headlineFontFamily: 'GrueneType',
          bodyFontFamily: 'PTSans',
          headlineTransform: 'none',
          bodyTransform: 'none',
          headlineFontWeight: 'normal',
          bodyFontWeight: 'normal',
          headlineFontStyle: 'italic',
          bodyFontStyle: 'none'
        }
      }
  }
}

export { getBaseUrl, getSupportInfo, getDefaultConfig, getThemeConfig }

<template>
  <div v-if="active" class="container stream pb-11">
    <div class="wrapper">
      <iframe :src="url" allowfullscreen scrolling="no" />
    </div>
    <v-radio-group
      v-model="url"
      row
    >
      <v-radio
        v-for="(item, i) in urls"
        :key="i"
        :label="item.text"
        :value="item.val"
      ></v-radio>
    </v-radio-group>
    <p class="text-center mt-4">{{ $store.state.currentPoll ? 'Aktuell: ' + $store.state.currentPoll.question : '' }}</p>
  </div>
  <div v-else>
    <p class="text-center mt-2">Der Stream für die BDK läuft noch nicht.</p>
  </div>
</template>

<script>
export default {
  name: 'Stream',
  data () {
    return {
      url: 'https://player.verdigado.com/videoplayer?event=oUaEXiuuAhKlPk56OxAOF',
      urls: [
        {
          text: 'Default',
          val: 'https://player.verdigado.com/videoplayer?event=oUaEXiuuAhKlPk56OxAOF'
        },
        {
          text: 'Gebärdensprache',
          val: 'https://player.verdigado.com/videoplayer?event=1VIt3gtGJaDa'
        },
        {
          text: 'Untertitel',
          val: 'https://player.verdigado.com/videoplayer?event=AGA3xEycGMtQ'
        }
      ],
      active: true
    }
  }
}
</script>

<style lang="scss">
  .stream {
    .wrapper {
      position: relative;
      padding-bottom: 57%;
    }

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 0;
    }

    .v-input--radio-group__input {
      justify-content: center;
    }

    .v-radio {
      .v-label,
      .v-icon {
        color: white !important;
      }
    }
  }
</style>

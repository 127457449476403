<template>
  <div class="container infos pb-11">
    <h2 class="text-center mb-12">Wichtige Infos</h2>
    <ul v-if="infos">
      <li
        v-for="(info, i) in infos"
        :key= i
        v-html="info"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Infos',
  data () {
    return {
      infos: [
        "Für die <strong>Gremienwahlen & die Abstimmung der Europaliste</strong> benötigt Ihr einen <strong>Televoter</strong>. Die Televoter werden auf den Tischen liegen. Damit ihr den Televoter benutzen könnt, braucht ihr eine <strong>personalisierte Chipkarte</strong> - die bekommt ihr bei der <strong>Delegierten Akkreditierung.</strong> Bitte holt die Chipkarte rechtzeitig, also am besten direkt beim Ankommen des jeweiligen Tages, ab.",
        "Eure <strong>Fragen</strong> an <strong>Margrethe Vestager</strong> könnt Ihr <a href='https://gruenlink.de/2pvl'>hier</a> stellen (Teilnahme möglich bis Freitag, 24. November um 17 Uhr).",
        "Auf unserem Parteitag dürfen sich nur Parteimitglieder in die Redeboxen einwerfen."
      ]
    }
  }
}
</script>

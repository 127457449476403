<template>
  <v-app :style="cssVars">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style :content="colors" />

<script>

export default {
  computed: {
    theme () {
      if (this.$route.path === '/results' && this.$store.state.config.views[5].theme) {
        return this.$store.state.config.views[5].theme
      }
      return this.$store.state.config.theme
    },
    cssVars () {
      return {
        '--primary': this.$store.state.config.colors.primary,
        '--background': this.$store.state.config.colors.background,
        '--headline-color': this.$store.state.config.colors.headline,
        '--button-color': this.$store.state.config.colors.button,
        '--highlight-color': this.$store.state.config.colors.highlight,
        '--link-color': this.$store.state.config.colors.link,
        '--headline-font': this.$store.state.config.fonts.headlineFontFamily,
        '--headline-transform': this.$store.state.config.fonts.headlineTransform,
        '--headline-font-weight': this.$store.state.config.fonts.headlineFontWeight,
        '--headline-font-style': this.$store.state.config.fonts.headlineFontStyle,
        '--results-text-color': this.$store.state.config.colors.text,
        '--results-body-font': this.$store.state.config.fonts.bodyFontFamily,
        '--results-body-transform': this.$store.state.config.fonts.bodyTransform,
        '--results-body-font-weight': this.$store.state.config.fonts.bodyFontStyle,
        '--results-body-font-style': this.$store.state.config.fonts.bodyFontStyle,
        '--results-hashtag-color': this.$store.state.config.colors.hashtag,
        '--results-bar-color': this.$store.state.config.colors.bar
      }
    }
  },
  beforeCreate () {
    const config = localStorage.getItem('config')
    if (config) {
      this.$store.commit('setConfig', JSON.parse(config))
    } else {
      this.$store.commit('setDefaultConfig')
    }
    // get support info from config
    this.$store.commit('setSupportInfo')

    this.$store.dispatch('initiateConnection', { meetingId: 'f717ed9e-cfb9-4afb-b935-5fd63577e83b', token: null })
  }
}
</script>

<style lang="scss">
  :root {
    --body-font: 'PTSans';
    --red: #ff345f;
  }

  @font-face { font-family: 'BereitBold';
             src: url('/fonts/BereitBold-Oblique.otf')  format('otf'),
                  url('/fonts/BereitBold-Oblique.woff2')  format('woff2'),
                  url('/fonts/BereitBold-Oblique.woff')  format('woff'); }

  @font-face { font-family: 'PTSans'; src: url('/fonts/ptsans-regular.woff')  format('woff2'); }

  @font-face {
    font-family: 'GrueneType';
    src: url('/fonts/GrueneType-BlackCondensedItalic.eot');
    src: url('/fonts/GrueneType-BlackCondensedItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/GrueneType-BlackCondensedItalic.woff2') format('woff2'),
    url('/fonts/GrueneType-BlackCondensedItalic.woff') format('woff'),
    url('/fonts/GrueneType-BlackCondensedItalic.ttf') format('truetype'),
    url('/fonts/GrueneType-BlackCondensedItalic.svg#GrueneType-BlackCondensedItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  h1 {
    font-family: var(--headline-font);
    font-style: var(--headline-font-style);
  }

  .v-application {
    font-family: 'PTSans', 'Roboto', sans-serif;
    overflow-x: hidden;

    .primary--text {
      color: var(--primary-color);
      caret-color: var(--primary-color);
    }

    a:not(.v-btn) {
      color: var(--link-color) !important;
      font-weight: bold;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .v-main {
    .v-btn {
      background-color: var(--button-color) !important;
      font-weight: bold;
      text-transform: none;
    }
  }

  .red--text {
    color: #ff345f !important;
  }

  html {
    overflow-y: auto !important;
  }

  .view,
  .dashboard {
    padding: 2rem;
  }

  th {
    color: rgb(0,0,0) !important;
    border-color: rgba(0,0,0,0.2);
  }
</style>

<template>
  <div class="container agenda">
    <img id="sunflower" alt="sunflower-logo" src="/sunflower.svg">
    <h2 class="text-center mb-12">Tagesordnung</h2>

    <div v-if="info" v-html="info" class="mb-12 text-center font-italic"></div>
    <div v-if="active && this.$store.state.agenda.days.length > 0">
      <v-tabs
        v-model="tab"
        background-color="transparent"
      >
        <v-tab
          v-for="(day, i) in this.$store.state.agenda.days"
          :key="i"
        >
          <span class="d-none d-md-inline" v-if="i === 0">Donnerstag</span>
          <span class="d-md-none" v-if="i === 0">Do</span>
          <span class="d-none d-md-inline" v-if="i === 1">Freitag</span>
          <span class="d-md-none" v-if="i === 1">Fr</span>
          <span class="d-none d-md-inline" v-if="i === 2">Samstag</span>
          <span class="d-md-none" v-if="i === 2">Sa</span>
          <span class="d-none d-md-inline" v-if="i === 3">Sonntag</span>
          <span class="d-md-none" v-if="i === 3">So</span>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(day, i) in this.$store.state.agenda.days"
          :key="i"
        >
          <Panels :day="i" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <Panels v-else-if="active" :day="0" />
    <div v-else>
      <p class="text-center my-8">Die Tagesordnung für die BDK ist noch nicht freigegeben.</p>
    </div>
  </div>
</template>

<script>

import Panels from '@/components/program/Panels.vue'

export default {
  name: 'Program',
  components: {
    Panels
  },
  data: () => ({
    active: true,
    info: null,
    tab: new Date().getDay() - 4
  })
}
</script>

<style lang="scss">
  #sunflower {
    width: 40vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(95%, -12%);
    z-index: -1;
    pointer-events: none;

    @media only screen and (max-width: 75rem) {
      display: none;
    }
  }

  .agenda {
    .v-expansion-panel:empty {
      display: none;
    }

    .highlight {
      position: relative;

      &:before {
        content: '\F0734';
        font-family: 'Material Design Icons';
        display: block;
        position: absolute;
        color: var(--highlight-color);
        left: -2.25rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.75rem;
        text-decoration: none;
      }
    }

    .v-tabs {
      margin-bottom: 4rem;

      @media only screen and (max-width: 40rem) {
        margin-bottom: 2rem;
      }
    }
  }
</style>

<template>
  <div class="view program">
    <h1 class="my-8 text-center">
      Digitale Plattform zur 49. BDK
    </h1>
    <div v-if="$store.state.connectionStatus === 'connected'">
      <Stream />
      <hr class="mt-6 mb-14" />
      <Infos />
      <hr class="mt-10 mb-14" />
      <Program />
      <hr class="mt-10 mb-14" />
      <Exhibit />
      <hr class="my-14" />
      <Links />
    </div>
    <div v-else-if="$store.state.connectionStatus === 'connecting'" class="pt-4">
      <p class="text-center">
        Verbindung wird aufgebaut...
      </p>
    </div>
    <div v-else class="pt-4 text-center">
      <p class="red--text">
        Du bist mit keinem Meeting verbunden!
      </p>
      <v-btn
        @click="goToLogin()"
        class="pa-6 mb-6 mr-6"
        color="#19A229"
        dark>
        Verbinden
      </v-btn>
    </div>
  </div>
</template>

<script>

import Program from '@/components/program/Program.vue'
import Exhibit from '@/components/program/Exhibit.vue'
import Stream from '@/components/program/Stream.vue'
import Infos from '@/components/program/Infos.vue'
import Links from '@/components/program/Links.vue'

export default {
  name: 'ProgramView',
  components: {
    Exhibit,
    Program,
    Stream,
    Infos,
    Links
  },
  methods: {
    goToLogin () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
  .program {
    min-height: 100%;
    background: var(--background);
    text-transform: var(--results-body-transform);
    color: var(--results-text-color);
    font-family: var(--results-body-font);
    font-weight: var(--results-body-font-weight);
    font-style: var(--results-body-font-style);

    h1 {
      font-size: 4rem;

      @media only screen and (max-width: 40rem) {
        font-size: 3.5rem;
      }
    }

    h2 {
      font-size: 3.5rem;
      font-family: var(--headline-font);
      font-weight: var(--headline-font-weight);
      font-style: var(--headline-font-style);

      @media only screen and (max-width: 40rem) {
        font-size: 2.5rem;
      }
    }

    h3 {
      font-size: 2rem;

      @media only screen and (max-width: 40rem) {
        font-size: 1.75rem;
      }
    }

    h4 {
      font-size: 1.5rem;

      @media only screen and (max-width: 40rem) {
        font-size: 1.25rem;
      }
    }

    .container,
    hr {
      margin-left: auto;
      margin-right: auto;
    }

    .container {
      max-width: 65rem;
      position: relative;
      z-index: 0;
    }

    hr {
      max-width: 63.5rem;
      opacity: .5;
    }

    .v-tabs-items {
      background: none;
    }

    .v-tab,
    .v-tabs-slider-wrapper {
      color: white !important;
      font-family: "GrueneType", sans-serif;
      font-size: 1.25rem;
    }

    .v-item-group {
      justify-content: center;
    }

    .v-slide-group__wrapper {
      flex: none;
    }

    .v-expansion-panels .v-expansion-panel {
      background: transparent;
      color: white;

      &:before,
      &:after {
        display: none;
      }
    }

    .v-expansion-panel-content__wrap {
      padding: 0;
    }

    .v-expansion-panel-header__icon {
      margin-left: 0;
    }

    .v-expansion-panel--active {
      .v-expansion-panel-header {
        display: none;
      }
    }
  }
</style>

<template>
  <v-carousel
    height="450"
    hide-delimiter-background
  >
  <v-carousel-item
    v-for="(slide, i) in slides"
    :key="i"
  >
    <div class="d-flex exhibit fill-height justify-center align-center pb-8">
      <img
        :class="slide.image.class"
        :src="slide.image.src"
        :alt="slide.image.alt" />
      <div class="text text-left pl-6">
        <h4>{{ slide.title }}</h4>
        <div class="place font-weight-bold mt-4">{{ slide.place }}</div>
        <div class="about mt-4" v-html="slide.text"></div>
      </div>
    </div>
  </v-carousel-item>
  </v-carousel>
</template>
<script>

export default {
  name: 'Slider',
  props: {
    slides: Array
  }
}
</script>
<style lang="scss">
.exhibits {
  &.container {
    max-width: 69rem !important;
  }

  .exhibit {
    height: 100%;
  }

  .text {
    color: var(--results-text-color);
  }

  .v-image {
    max-width: 61rem;
    margin: 0 auto;
  }

  .place {
    color: var(--highlight-color);
  }

  .v-window__prev {
    left: -1rem;
  }

  .v-window__next {
    right: -1rem;
  }

  .v-window__prev,
  .v-window__next,
  .v-carousel__controls__item {
    background: none;

    .v-btn {
      background: none !important;
    }
  }

  img {
    max-width: 220px;
  }

  @media only screen and (max-width: 40rem) {
    .exhibit {
      flex-direction: column;
    }

    .text {
      text-align: center !important;
      margin-top: 2rem;
    }

    .v-window,
    .v-image {
      height: 500px !important;
    }

    .v-carousel__controls {
      padding-bottom: .5rem;
    }
  }

  @media only screen and (max-width: 65rem) {
    .v-window__prev,
    .v-window__next {
      display: none;
    }
  }
}
</style>

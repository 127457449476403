// returns true if passed date is less than 2 mins ago
function getIsRecent (date) {
  const timestamp = new Date(date).getTime()
  const now = new Date().getTime()
  return (now - timestamp > 0 && now - timestamp < 120000)
}

// returns time in format 'hh:mm'
function getFormattedTime (date) {
  if (date) {
    const hours = date.getHours()
    const mins = date.getMinutes()
    return (hours < 10 ? '0' + hours : hours) + ':' + (mins < 10 ? '0' + mins : mins)
  }
  return '-'
}

function getDay (start, current) {
  const ms = 86400000
  const startDay = Math.floor(start / ms)
  const currentDay = Math.floor(current / ms)
  return currentDay - startDay
}

function parseText (text) {
  if (text && text.length) {
    const htmlRegex = /<.+?>/gi
    const boldRegex = /\*\*(.+)\*\*/gi
    const italicRegex = /\*(.+)\*/gi
    const linkRegex = /\[(.+?)]\s?\((\w+:\/\/(.+?))\)/gi
    const newLineRegex = /#/g
    text = text.replace(htmlRegex, '')
    text = text.replace(boldRegex, '<strong>$1</strong>')
    text = text.replace(italicRegex, '<em>$1</em>')
    text = text.replace(linkRegex, '<a href="$2">$1</a>')
    text = text.replace(newLineRegex, '</p><p>')
    text = '<p>' + text + '</p>'
    return text
  }
  return ''
}

function getPollStatus (status) {
  switch (status) {
    case 'closed':
      return 'Geschlossen'
    case 'open':
      return 'Offen'
    case 'result':
      return 'Ergebnis'
    case 'prepare':
      return 'Vorbereitung'
    default:
      return 'Überprüfung'
  }
}

function getPollType (type) {
  switch (type) {
    case 'pool':
      return 'Abstimmung'
    case 'program':
      return 'Programm'
    default:
      return type
  }
}

function getSpeakerStatus (item) {
  if (item.speaking) {
    return 'LIVE'
  } else if (item.conference_activated) {
    return 'Zugeschaltet'
  } else if (item.technology_checked) {
    return 'Bereit'
  } else if (item.conference_joined) {
    return 'Im Jitsi Raum'
  } else if (item.pulled) {
    return 'Gezogen'
  } else {
    return 'Nicht gezogen'
  }
}

function getGender (gender) {
  if (gender === 'female') {
    return 'weiblich'
  }
  if (gender === 'male') {
    return 'männlich'
  }
  return gender ? 'divers' : '-'
}

function getPollResultOptions (pollGroups) {
  const groups = []
  pollGroups.forEach((group) => {
    const options = []
    group.options.forEach((option) => {
      options.push({
        id: option.id,
        title: option.title
      })
    })
    groups.push({
      title: group.name,
      options: options
    })
  })
  return groups
}

function getMappedPoll (item, isCurrent = false) {
  return {
    id: item.id,
    question: item.question,
    type: getPollType(item.type),
    isCurrent: isCurrent,
    status: getPollStatus(item.state),
    questionsEnabled: item.allow_questions,
    candidacyEnabled: item.allow_candidatures,
    speechBoxEnabled: !!item.speech_boxes_enabled,
    description: parseText(item.description)
  }
}

function getMotionStatusText (status) {
  switch (status) {
    case 'revoked':
    case 'rejected':
    case 'accepted':
      return null
    case 'vote':
      return 'Abstimmung'
    case 'clarifying':
      return 'In Klärung'
    default:
      return 'Neu'
  }
}

function getMappedMotion (item) {
  const status = getMotionStatusText(item.status)
  if (status) {
    return {
      id: item.id,
      description: item.description,
      movant: item.user ? item.user.name : item.name,
      chapter: item.user ? item.user.division : '-',
      status: status,
      time: getFormattedTime(new Date(item.created)),
      isNew: getIsRecent(new Date(item.created))
    }
  }
}

function getMappedSpeaker (item) {
  return {
    id: item.id,
    speaker: item.name,
    status: getSpeakerStatus(item),
    box: item.box.name,
    division: item.user ? item.user.division : '-'
  }
}

function getCurrentDelegation (delegations) {
  if (!delegations) return null
  for (const i in delegations) {
    if (delegations[i].current) {
      return delegations[i]
    }
  }
}

function getMappedPolls (polls, currentPollId) {
  const mappedPolls = []
  for (const i in polls) {
    if (polls[i].state === 'prepare' && polls[i].type === 'pool') {
      const isCurrentPoll = polls[i].id === currentPollId
      mappedPolls.push(getMappedPoll(polls[i], isCurrentPoll))
    }
  }
  return mappedPolls
}

function getMappedAgendaItem (allPolls, item, start, currentPollId) {
  const filteredPolls = []
  let hasCurrent = false
  for (const i in allPolls) {
    for (const j in item.pools) {
      const poll = allPolls[i]
      if (poll.id === item.pools[j].id) {
        const isCurrentPoll = poll.id === currentPollId
        hasCurrent = isCurrentPoll || hasCurrent
        filteredPolls.push(getMappedPoll(poll, isCurrentPoll))
      }
    }
  }

  return {
    id: item.id,
    title: item.title || '-',
    start: start,
    polls: filteredPolls,
    current: hasCurrent,
    description: parseText(item.description)
  }
}

function getAgenda (agenda, polls, currentPollId) {
  const days = []
  let showDate = false
  let i = 0
  let earliest = null
  for (const j in agenda) {
    const item = agenda[j]
    let start = ''
    if (item.start_date) {
      showDate = true
      start = new Date(item.start_date)
      if (!earliest) {
        earliest = start
      }
      i = getDay(earliest, start)
    }
    if (!days[i]) {
      days[i] = {
        items: [],
        current: getDay(start, new Date()) === 0
      }
    }
    days[i].items.push(getMappedAgendaItem(polls, item, getFormattedTime(start), currentPollId))
  }
  return { days: days, showDate: showDate }
}

function getSpeakersFromBox (box) {
  const speakers = []
  const items = box.pulledSpeakers.concat(box.unpulledSpeakers)
  for (const i in items) {
    speakers.push(getMappedSpeaker(items[i]))
  }
  return speakers
}

function getSpeakersFromBoxes (speeches, boxes) {
  let speakers = []
  for (const i in speeches.boxes) {
    const box = speeches.boxes[i]
    for (const j in boxes) {
      if (box.name === boxes[j]) {
        speakers = speakers.concat(getSpeakersFromBox(box))
      }
    }
  }
  return speakers.length ? speakers : null
}

function getActiveSpeakers (speeches) {
  const speakers = []
  if (speeches) {
    for (const i in speeches.activeSpeakers) {
      speakers.push(getMappedSpeaker(speeches.activeSpeakers[i]))
    }
  }
  return speakers.length ? speakers : null
}

function getMappedQuestions (data) {
  const questions = []
  let count = 0
  let countWomen = 0
  let countWomenPulled = 0

  let allQuestions = []
  for (const i in data) {
    allQuestions = allQuestions.concat(data[i].pulledQuestions)
    allQuestions = allQuestions.concat(data[i].unpulledQuestions)
  }

  for (const i in allQuestions) {
    const comp = (a, b) => {
      return a.pulled - b.pulled
    }

    allQuestions.sort(comp)

    const question = allQuestions[i]
    const gender = question.gender ? question.gender.toLowerCase() : '-'
    count++
    if (gender === 'weiblich') {
      countWomen++
    }
    if (question.pulled) {
      if (gender === 'weiblich') {
        countWomenPulled++
      }
      questions.push({
        text: question.question,
        name: question.displayName ? question.displayName : '-',
        gender: gender,
        division: question.kv ? question.kv : '-'
      })
    }
  }

  return { questions: questions, total: count, women: countWomen, womenPulled: countWomenPulled }
}

function getMappedCandidacies (data) {
  const candidacies = []

  for (const i in data.all) {
    const candidacy = data.all[i]
    candidacies.push({
      name: candidacy.user ? candidacy.user.name : '-',
      gender: getGender(candidacy.user.gender),
      status: candidacy.revoked ? 'zurückgezogen' : 'aktiv',
      division: candidacy.user && candidacy.user.division ? candidacy.user.division : '-'
    })
  }

  return candidacies
}

function getLastActivated (speaker1, speaker2) {
  if (speaker1 && !speaker2) {
    return speaker1
  }
  if (!speaker1 && speaker2) {
    return speaker2
  }
  return (speaker1.conference_activated > speaker2.conference_activated) ? speaker1 : speaker2
}

function getActivatedSpeaker (speakers) {
  let lastActivatedSpeaker = null
  for (const i in speakers) {
    const speaker = speakers[i]
    if (speaker.conference_activated) {
      lastActivatedSpeaker = getLastActivated(speaker, lastActivatedSpeaker)
    }
  }
  return lastActivatedSpeaker
}

function getConferenceLink (current) {
  // get first ready speaker
  const speaker = getActivatedSpeaker((current.active_speakers))
  if (speaker) {
    const speakerId = speaker.user ? speaker.user.id : speaker.id
    if (speakerId && current.meeting_rooms[speakerId]) {
      return current.meeting_rooms[speakerId]
    }
  }
  return null
}

function getConferenceData (current) {
  const data = {}
  const link = getConferenceLink(current)
  if (!link) {
    return null
  }
  if (link.search('konferenz.netzbegruenung.de') < 0) {
    data.link = link
  } else {
    data.meetingId = link.split('konferenz.netzbegruenung.de/')[1]
  }
  return data
}

function handleExceptions (context, exceptions) {
  if (Object.keys(exceptions).length) {
    context.commit('setExceptionCount', context.state.exceptionCount + 1)
    for (const exception in exceptions) {
      // eslint-disable-next-line
      console.error('%c Exception in ' + exception + ': %c' + exceptions[exception], 'font-weight: bold;', 'font-style: italic')
    }
  } else {
    context.commit('setExceptionCount', 0)
  }
}

function getVoteCountForOptionGroup (group, voteData) {
  let count = 0
  group.options.forEach((option) => {
    voteData.options.forEach((result) => {
      if (result.id === option.id) {
        count += result.count
      }
    })
  })
  return count
}

function calculatePercentage (voteCount, totalVotes) {
  const percentage = voteCount / totalVotes * 100
  return percentage ? Math.round(percentage * 100) / 100 : 0
}

function getPollResultsForGroup (group, voteData, multiselect) {
  const totalVotes = multiselect ? getVoteCountForOptionGroup(group, voteData) : voteData.count
  group.options.forEach((option) => {
    voteData.options.forEach((result) => {
      if (result.id === option.id) {
        option.votes = result.count
        option.percentage = calculatePercentage(result.count, totalVotes)
      }
    })
  })
  return group
}

// show warning if there may have been a race condition
// causing a vote to be counted twice
function checkVoteData (voteData, context) {
  let count = 0
  for (let i = 0; i < voteData.options.length; i++) {
    count += voteData.options[i].count
  }
  if (!count || count === voteData.count) {
    context.commit('setVoteError', null)
  } else {
    const data = {}
    data.voteCount = voteData.count
    data.sumOptionCount = count
    context.commit('setVoteError', data)
  }

  return voteData
}

function getGroupedPollResults (pollData, voteData, context) {
  const mappedResults = []

  if (!pollData.multiselect_groups) {
    voteData = checkVoteData(voteData, context)
  }

  if (pollData && pollData.groups && pollData.groups.length) {
    const optionGroups = getPollResultOptions(pollData.groups)
    if (voteData && optionGroups) {
      optionGroups.forEach((group) => {
        mappedResults.push(getPollResultsForGroup(group, voteData, pollData.multiselect_groups))
      })
    }
  }
  return mappedResults
}

export { getConferenceData, getAgenda, getFormattedTime, getMappedQuestions, getMappedCandidacies, getActiveSpeakers, getSpeakersFromBoxes, getCurrentDelegation, getPollType, getMappedPolls, getPollStatus, getGroupedPollResults, getMappedPoll, getMappedMotion, getMappedSpeaker, handleExceptions }

<template>
  <v-expansion-panels
    multiple
    :value="open"
    class="pl-8">
    <v-expansion-panel
      v-for="item in $store.state.agenda.days[day].items"
      :key="item.id"
      class="mb-4">
      <div v-if="item.polls.length">
        <h3 :class="{ 'text-decoration-underline': item.current }">{{ item.title }}</h3>
        <div v-if="item.description" v-html="item.description" class="mt-6"></div>
      </div>
      <v-expansion-panel-header
        v-if="item.polls.length > 1"
        class="my-2 px-0">
        <template v-slot:actions>
          <div class="font-weight-bold">Mehr <v-icon color="white">mdi-chevron-down</v-icon></div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content
        v-if="item.polls.length > 1"
        class="mt-6">
        <div
          v-for="poll in item.polls"
          :key="poll.id"
          class="mb-4">
          <h4 :class="{ 'highlight': poll.isCurrent }">
            {{ poll.question }}
          </h4>
          <div v-if="poll.description" v-html="poll.description" class="my-2"></div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

export default {
  name: 'Panels',
  data: () => ({
    openPanels: []
  }),
  props: {
    day: Number
  },
  computed: {
    open () {
      const panels = this.openPanels
      const items = this.$store.state.agenda.days[this.day].items
      for (const i in items) {
        if (items[i].current && !panels.includes(parseInt(i))) {
          panels.push(parseInt(i))
        }
      }
      return panels
    }
  }
}
</script>

<style scoped>

</style>
